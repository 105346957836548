'use client';

import ErrorPage from '@/components/ErrorPage/ErrorPage';

const NotFound = () => {
  const handleOnClick = (e: any) => {
    e.preventDefault();
    window.location.href = '/';
  };
  return <ErrorPage handleOnClick={handleOnClick} />;
};

export default NotFound;
